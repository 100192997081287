import { init, captureException, Severity } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import type { CaptureContext } from '@sentry/types';

init({
  dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_ENVIRONMENT,
  tunnel: `/error-tracing`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // Let's start with 1% of requests.
  tracesSampleRate: import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION' ? 0.01 : 0.1,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'LOCAL',
});

export const errorProvider = {
  error(err: any, context: CaptureContext = {}) {
    captureSentryException(err, context, Severity.Error);
  },
  warning(err: any, context: CaptureContext = {}) {
    captureSentryException(err, context, Severity.Warning);
  },
  log(err: any, context: CaptureContext = {}) {
    captureSentryException(err, context, Severity.Debug);
  },
  info(err: any, context: CaptureContext = {}) {
    captureSentryException(err, context, Severity.Info);
  },
  debug(err: any, context: CaptureContext = {}) {
    console.log(err, context);
  },
};

function captureSentryException(err: any, context: CaptureContext, level: Severity) {
  captureException(err, {
    level,
    ...context,
  });
  console.error(err, context);
  const stack = err?.stack || Error().stack;
  console.error(stack);
}
